












import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import InventoryForm from '@/components/InventoryForm.vue'
import { Project } from '@/interfaces'

export default Vue.extend({
  components: {
    Nav,
    InventoryForm,
  },
  methods: {
    inventoryCreated(project: Project) {
      if (project.id) {
        this.$router.push({
          name: 'inventory_edit',
          params: {
            projectId: project.id.toString(),
            inventoryId: project.inventory?.id ? project.inventory.id.toString() : '',
          },
        })
      }
    },
  },
})
